import React from 'react';
import ReactDOM from 'react-dom/client';
import { Container, Row } from "react-bootstrap";
import reportWebVitals from './reportWebVitals';
import Header from './components/header'
import Footer from './components/footer';
import Navigation from './components/navigation';

import './scss/site.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <Navigation />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
