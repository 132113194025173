import OctalComponentPage from '../../components/octal-component-page'

export default function OpenPolicyAgent() {
    return (
        <OctalComponentPage
            title="Open Policy Agent"
            overview="Open Policy Agent (OPA) is an open source, general-purpose policy engine that enables unified, context-aware policy enforcement across the entire stack.
            OPA is proud to be a graduated project in the [Cloud Native Computing Foundation](https://cncf.io) (CNCF) landscape. For details read the CNCF [announcement](https://www.cncf.io/announcements/2021/02/04/cloud-native-computing-foundation-announces-open-policy-agent-graduation/)."
            module_name="terraform-kubernetes-opa"
            module_license="Apache License 2.0"
            service_license="Apache License 2.0"
            details={{
                service_homepage: "https://www.openpolicyagent.org/",
                service_documentation: "https://www.openpolicyagent.org/docs/latest/",
                module_repository: "TBD",
                module_registry: "TBD"
            }}
            related_modules={[                

            ]}
            example_code={`
            
            `}
        />
    );
}