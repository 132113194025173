
import React from "react"

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="bg-light text-light font-small blue pt-4 mt-4">
                <div className="text-center text-md-left">
                    <div className="row">
                        <h6 id="have-questions-or-suggestions">Have questions or suggestions?</h6>
                        <p>Come join the <a href="https://discord.gg/RyUrTAsa3C">Project-Octal Discord</a> server!</p>
                        <hr className="clearfix w-100 d-md-none pb-0" />
                    </div>
                </div>
            </footer>
        );
    }
}