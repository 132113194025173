
import React from "react"
import Divider from '../components/divider'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Container, Row, Col, Card } from "react-bootstrap";

export default class OctalComponentPage extends React.Component {

	renderOverview() {
		return (
			<Col>
				<h3>Overview</h3>
				<p><ReactMarkdown children={this.props.overview} remarkPlugins={[remarkGfm]} /></p>
			</Col>
		)
	}

	renderComponentDetails() {
		return (
			<Col md="6" className="d-flex">
				<Card>
					<Card.Header><span style={{ "font-weight": "bolder" }}>Component Details</span></Card.Header>
					<Card.Body>
						<Card.Text>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Module Name:  </span>{this.props.module_name}</span>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Module License: </span>{this.props.module_license}</span>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Service License: </span>{this.props.service_license}</span>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Service Homepage:  </span><a href={this.props.details.service_homepage}>{this.props.details.service_homepage}</a></span>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Service Documentation: </span><a href={this.props.details.service_documentation}>{this.props.details.service_documentation}</a></span>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Module Repository: </span><a href={this.props.details.module_repository}>{this.props.details.module_repository}</a></span>
							<span className="d-block"><span style={{ "font-weight": "500" }}>Module Registry: </span><a href={this.props.details.module_registry}>{this.props.details.module_registry}</a></span>
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
		)
	}

	renderComponentLinks() {

	}

	renderGettingStarted() {
		return (
			<Row>
				<h3>Getting Started</h3>
				<pre><code style={{ maxHeight: "500px" }} className="language-hcl">{this.props.example_code}</code></pre>
			</Row>
		)
	}

	renderRelatedModuleCards() {
		let relatedModules = []
		for (var i = 0; i < this.props.related_modules.length; i++) {
			relatedModules.push(
				<Card className="m-3 rounded-3 shadow-sm" style={{ "maxWidth": "25em" }}>
					<Card.Header><span style={{ "font-weight": "bolder" }}>{this.props.related_modules[i].name}</span></Card.Header>
					<Card.Body>
						<Card.Text>
							<p>{this.props.related_modules[i].description}</p>
						</Card.Text>
					</Card.Body>
					<Card.Footer>
						<div className="d-flex justify-content-around">
							<div><a href={this.props.related_modules[i].github_link}>Docs</a></div>
							<div><a href={this.props.related_modules[i].github_link}>Github</a></div>
							<div><a href={this.props.related_modules[i].github_link}>Registry</a></div>
						</div>
					</Card.Footer>
				</Card>
			)
		}

		return (
			<Row>
				<h3>Related Modules</h3>
				<Container className="d-flex flex-wrap justify-content-around">
					{relatedModules}
				</Container>
			</Row>
		)
	}

	// <!-- img style={{ "maxHeight": "10em" }} src="https://project-octal-static-assets.nyc3.digitaloceanspaces.com/project-octal-logo-argo.svg" />
	render() {
		return (
			<Container className="p-0">

				<Row>
					<h2 className="my-1">{this.props.title}</h2>
				</Row>

				<Divider />

				<Row className="mt-1">

					{this.renderOverview()}
					{this.renderComponentDetails()}
					
				</Row>

				{this.renderGettingStarted()}

				<Divider />

				{this.renderRelatedModuleCards()}

			</Container>
		);
	}
}