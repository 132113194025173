import { Container } from "react-bootstrap";

export function NotFound() {
    return (
        <>
            <Container>
                NOT FOUND
            </Container>
        </>
    );
}