import { Container } from 'react-bootstrap'
import Divider from '../components/divider'
import OctalComponentCard from '../components/octal-component-card'
import OctalIntroduction from '../components/octal-introduction'

export default function Home() {
    return (
        <>
           <OctalIntroduction />

            <Divider />

            <Container className="d-flex flex-wrap justify-content-center">

            <OctalComponentCard 
                imgSrc="./assets/images/project-octal-argocd.webp"
                title="ArgoCD"
                description="Declarative continuous delivery with a fully-loaded UI."
                license="Apache 2.0"
                features={[
                    "CICD Pipelines",
                    "End user self-service"
                ]}
                relatedModules={[
                    "terraform-kubernetes-argocd-project",
                    "terraform-kubernetes-argocd-application"
                ]}
            />

            <OctalComponentCard 
                imgSrc="./assets/images/project-octal-traefik.webp"
                imgRight
                title="Traefik"
                description="Cloud-Native Networking Stack That Just Works."
                license="MIT"
                features={[
                    "Ingress networking"
                ]}
                relatedModules={[
                    "terraform-traefik-ingress-route",
                    "terraform-kubernetes-oauth-endpoint",
                ]}
            />

            <OctalComponentCard 
                imgSrc="./assets/images/project-octal-cert-manager.webp"
                title="Cert-Manager"
                description="Automatically provision and manage TLS certificates in Kubernetes."
                license="Apache 2.0"
                features={[
                    "TLS certificate lifecycle management"
                ]}
                relatedModules={[
                    "terraform-kubernetes-cert-manager-issuers"
                ]}
            />

            <OctalComponentCard 
                imgSrc="./assets/images/project-octal-opa.webp"
                imgRight
                title="Open Policy Agent"
                description="An open source, general-purpose policy engine."
                license="Apache 2.0"
                features={[
                    "Strong policy enforcement"
                ]}
                relatedModules={[

                ]}
            />

            <OctalComponentCard 
                imgSrc="./assets/images/project-octal-linkerd2.webp"
                title="Linkerd2"
                description="Ultralight, security-first service mesh for Kubernetes."
                license="Apache 2.0"
                features={[
                    "Service mesh",
                    "Cluster network observability"
                ]}
                relatedModules={[

                ]}
            />
            </Container>
        </>
    );
}