import OctalComponentPage from '../../components/octal-component-page'

export default function ArgoCD() {
    return (
        <OctalComponentPage 
            title="ArgoCD"
            overview="Argo CD is a declarative, GitOps continuous delivery tool for Kubernetes."
            module_name="terraform-kubernetes-argocd"
            module_license="Apache License 2.0"
            service_license="Apache License 2.0"
            details={{
                service_homepage: "https://argoproj.github.io/cd/",
                service_documentation: "https://argo-cd.readthedocs.io/en/stable/",
                module_repository: "https://github.com/project-octal/terraform-kubernetes-argocd",
                module_registry: "https://registry.terraform.io/modules/project-octal/argocd/kubernetes" 
            }}
            related_modules={[
                {
                    "name": "terraform-kubernetes-argocd-project",
                    "description": "A Terraform module for creating ArgoCD Project manifests.",
                    "documentation_link": "",
                    "github_link": "https://github.com/project-octal/terraform-kubernetes-argocd-project",
                    "module_repo_link": "https://registry.terraform.io/modules/project-octal/argocd-project/kubernetes/latest"
                },
                {
                    "name": "terraform-kubernetes-argocd-application",
                    "description": "A Terraform module for deploying ArgoCD application manifests.",
                    "documentation_link": "",
                    "github_link": "https://github.com/project-octal/terraform-kubernetes-argocd-application",
                    "module_repo_link": "https://registry.terraform.io/modules/project-octal/argocd-application/kubernetes/latest"
                }
            ]}
            example_code={`
            module "argocd" {
                source  = "project-octal/argocd/kubernetes"
                version = "1.0.0"
            
                namespace              = "kube-argocd"
                argocd_server_replicas = 2
                argocd_repo_replicas   = 2
                enable_dex             = false
            
                ingress_enabled    = true
                ingress_host       = "argocd.arroyo.turnbros.app"
                ingress_path       = "/"
                ingress_class_name = module.traefik.ingress_class
                ingress_annotations = {
                    "traefik.ingress.kubernetes.io/router.entrypoints" : "websecure"
                    "traefik.ingress.kubernetes.io/router.tls" : "true"
                }
                ingress_cert_issuer_annotation = {
                    "cert-manager.io/cluster-issuer" : module.cert_manager.cert_issuer
                }
            
                argocd_server_requests = {
                    cpu = "300m"
                    memory = "256Mi"
                }
                argocd_server_limits = {
                    cpu = "600m"
                    memory = "512Mi"
                }
            
                repo_server_exec_timeout = "300"
                argocd_repo_requests = {
                    cpu = "300m"
                    memory = "256Mi"
                }
                argocd_repo_limits = {
                    cpu = "600m"
                    memory = "512Mi"
                }
                argocd_repositories = [
                    {
                        name = "Helm-Main"
                        type = "helm"
                        url = "https://charts.helm.sh/stable"
                    }
                ]
            
                oidc_config = {
                    name                      = var.argocd_oidc_name
                    issuer                    = var.argocd_oidc_issuer
                    client_id                 = var.argocd_oidc_client_id
                    client_secret             = var.argocd_oidc_client_secret
                    requested_scopes          = var.argocd_oidc_requested_scopes
                    requested_id_token_claims = var.argocd_oidc_requested_id_token_claims
                }
            }
            `}
        />
    );
}
