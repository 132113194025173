
import React from "react"
import { Container } from "react-bootstrap";

export default class Divider extends React.Component {
    render() {
        return (
            <Container className="p-0">
                <hr />
            </Container>
        );
    }
}