import OctalComponentPage from '../../components/octal-component-page'

export default function Linkerd2() {
    return (
        <OctalComponentPage
            title="Linkerd2"
            overview="
            Linkerd is an ultralight, security-first service mesh for Kubernetes. Linkerd
            adds critical security, observability, and reliability features to your
            Kubernetes stack with no code change required.
            
            Linkerd is a Cloud Native Computing Foundation ([CNCF][cncf]) project.
            "
            module_name="terraform-kubernetes-linkerd2"
            module_license="Apache License 2.0"
            service_license="Apache License 2.0"
            details={{
                service_homepage: "https://linkerd.io/",
                service_documentation: "https://linkerd.io/2.11/overview/",
                module_repository: "https://github.com/project-octal/terraform-kubernetes-linkerd2",
                module_registry: "https://registry.terraform.io/modules/project-octal/linkerd2/kubernetes"
            }}
            related_modules={[
                
            ]}
            example_code={`
            
            `}
        />
    );
}