
import React from "react"
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";

import { Container, Row, Nav, Navbar, NavDropdown } from "react-bootstrap";

import Home from '../pages/home';
import OctalCore from '../pages/octal-core';
import OpenPolicyAgent from '../pages/octal-core/open-policy-agent';
import CertManager from '../pages/octal-core/cert-manager';
import Traefik from '../pages/octal-core/traefik';
import ArgoCD from '../pages/octal-core/argocd';
import Linkerd2 from '../pages/octal-core/linkerd2';
import { OctalExtras } from '../pages/octal-extras';
import { Roadmap } from '../pages/roadmap';
import { NotFound } from '../pages/not-found';
import { SelectionCriteria } from '../pages/selection-criteria';

export default class Navigation extends React.Component {
	render() {
		return (
			<Container  fluid>
				<Row className='shadow-sm mb-3'>
					<Navbar sticky="bottom" bg="light" variant="light">
						<Container className='p-0'>
							<Nav className="me-auto">
								<Nav.Link href="/" className="fw-bolder">Home</Nav.Link>
								<NavDropdown title="Octal-Core" className="fw-bolder">
									<NavDropdown.Item href="/octal-core/open-policy-agent" className="fw-lighter">Open Policy Agent</NavDropdown.Item>
									<NavDropdown.Item href="/octal-core/cert-manager" className="fw-lighter">Cert-Manager</NavDropdown.Item>
									<NavDropdown.Item href="/octal-core/traefik" className="fw-lighter">Traefik</NavDropdown.Item>
									<NavDropdown.Item href="/octal-core/argocd" className="fw-lighter">ArgoCD</NavDropdown.Item>
									<NavDropdown.Item href="/octal-core/linkerd2" className="fw-lighter">Linkerd2</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title="Octal-Extras" className="fw-bolder">
									<NavDropdown.Item href="/octal-extras/octal-tenant" className="fw-lighter">Octal-Tenant</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title="Documentation" className="fw-bolder">
									<NavDropdown.Item href="/documentation/roadmap" className="fw-lighter">Roadmap</NavDropdown.Item>
									<NavDropdown.Item href="/documentation/selection-criteria" className="fw-lighter">Tool Selection</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Container>
					</Navbar>
				</Row>

				<Router>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/octal-core" element={<OctalCore />} />
						<Route path="/octal-core/open-policy-agent" element={<OpenPolicyAgent />} />
						<Route path="/octal-core/cert-manager" element={<CertManager />} />
						<Route path="/octal-core/traefik" element={<Traefik />} />
						<Route path="/octal-core/argocd" element={<ArgoCD />} />
						<Route path="/octal-core/linkerd2" element={<Linkerd2 />} />
						<Route path="/octal-extras" element={<OctalExtras />} />
						<Route path="/documentation/roadmap" element={<Roadmap />} />
						<Route path="/documentation/selection-criteria" element={<SelectionCriteria />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Router>

			</Container>
		);
	}
}