import { Container } from "react-bootstrap";

export default function OctalCore() {
    return (
        <>
            <Container>
                OctalCore
            </Container>
        </>
    );
}