
import React from "react"
import { Parallax } from "react-parallax";
import { Col } from "react-bootstrap";

export default class Header extends React.Component {
    render() {

        //const bannerImage = "/assets/images/19742.jpg";
        const bannerImage = "/assets/images/hex-background.webp";
        const globalBlurStyle = {
            min: -1,
            max: 3
        }
        
        const logoImage = '/assets/images/project-octal-banner.svg'
        const logoStyle = {
            "max-width":  "30em",
            "max-height": "30em",
            "min-width":  "10em",
            "min-height": "10em",
        }
        const logoContainerStyle = {
            width: "100%",
            height: "100%",
        };
          
        return (
            <Col>
            <Parallax bgImage={bannerImage} blur={globalBlurStyle}>
              <div style={{ height: 400 }}>
                <div className="justify-content-center align-items-center d-flex" style={logoContainerStyle}>
                  <img style={logoStyle} src={logoImage} />
                </div>
              </div>
            </Parallax>
          </Col>
        );
    }
}