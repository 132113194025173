import { Container } from "react-bootstrap";

export function OctalExtras() {
    return (
        <>
            <Container>
                OctalExtras
            </Container>
        </>
    );
}