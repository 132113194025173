
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default class OctalIntroduction extends React.Component {

    constructor(props) {
        super(props);
        let windowDims = getWindowDimensions()
        this.state = {
            windowWidth: windowDims.width
        };
    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    

    render() {
        const descRowStyle = {
            "margin-bottom": "2em",
            "max-height": '700px'
        }
        const componentImageStyle = {
            margin: "auto"
        }
        const narrowComponentImageStyle={
            "max-height": "75%"
        }


        if (this.state.windowWidth > 769) {
            return (
                <Container>
                    <Row>
                        <Col md="8">
                            <Row style={{ height: "50%" }}>
                                <Container className="mt-5">
                                    <h2 id="what-is-project-octal">What is Project Octal?</h2>
                                    <p>Project Octal is a set of open source projects and configurations collated into a set of Terraform modules aimed at simplifying and standardizing the operationalization of Kubernetes clusters in enterprise environments.</p>
                                </Container>
                            </Row>
                            <Row style={{ height: "50%" }}>
                                <Container>
                                    <h3 id="why-project-octal">Why Project Octal?</h3>
                                    <p>Kubernetes has given rise to a thriving ecosystem of tooling, much of which is open source, and some of which is mature and well documented. The intent of Project Octal is simply to select from this vast ecosystem, mature, well documented tooling ideally with available paid enterprise support that satisfies a specific use-case that can be easily operationalized using Terraform modules.</p>
                                </Container>
                            </Row>
                        </Col>
                        <Col className="align-items-center align-self-center justify-content-center" md="4">
                            <img style={componentImageStyle} src="./assets/images/octal-components.svg"></img>
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Row style={{ height: "50%" }}>
                        <Container className="mt-5">
                            <h2 id="what-is-project-octal">What is Project Octal?</h2>
                            <p>Project Octal is a set of open source projects and configurations collated into a set of Terraform modules aimed at simplifying and standardizing the operationalization of Kubernetes clusters in enterprise environments.</p>
                        </Container>
                    </Row>
                    <Row>
                        <Col className="align-items-center justify-content-center d-flex" md="4">
                            <img style={narrowComponentImageStyle} src="./assets/images/octal-components.svg"></img>
                        </Col>
                    </Row>
                    <Row style={{ height: "50%" }}>
                        <Container>
                            <h3 id="why-project-octal">Why Project Octal?</h3>
                            <p>Kubernetes has given rise to a thriving ecosystem of tooling, much of which is open source, and some of which is mature and well documented. The intent of Project Octal is simply to select from this vast ecosystem, mature, well documented tooling ideally with available paid enterprise support that satisfies a specific use-case that can be easily operationalized using Terraform modules.</p>
                        </Container>
                    </Row>
                </Container>
            )
        }
    }

}