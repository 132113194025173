import OctalComponentPage from '../../components/octal-component-page'

export default function CertManager() {
    return (
        <OctalComponentPage 
            title="Cert-Manager"
            overview="Argo CD is a declaraticert-manager is a Kubernetes add-on to automate the management and issuance of
            TLS certificates from various issuing sources. It will ensure certificates are valid and up to date periodically, and attempt
            to renew certificates at an appropriate time before expiry. It is loosely based upon the work of [kube-lego](https://github.com/jetstack/kube-lego)
            and has borrowed some wisdom from other similar projects e.g.
            [kube-cert-manager](https://github.com/PalmStoneGames/kube-cert-manager).ve, GitOps continuous delivery tool for Kubernetes."
            module_name="terraform-kubernetes-cert-manager"
            module_license="Apache License 2.0"
            service_license="Apache License 2.0"
            details={{
                service_homepage: "https://cert-manager.io",
                service_documentation: "https://cert-manager.io/docs/",
                module_repository: "https://github.com/project-octal/terraform-kubernetes-cert-manager",
                module_registry: "https://registry.terraform.io/modules/project-octal/cert-manager/kubernetes" 
            }}
            related_modules={[
                {
                    "name": "terraform-kubernetes-cert-manager-issuers",
                    "description": "Simplifies the deployment and management of Jetstacks cert-manager certificate issuers on a Kubernetes cluster.",
                    "documentation_link": "",
                    "github_link": "https://github.com/project-octal/terraform-kubernetes-cert-manager-issuers",
                    "module_repo_link": ""
                }
            ]}
            example_code={`
            module "cert_manager" {
                source = "github.com/project-octal/terraform-kubernetes-cert-manager"
              
                certificate_issuers = {
                  letsencrypt = {
                    name              = "letsencrypt-prod"
                    server            = "https://acme-v02.api.letsencrypt.org/directory"
                    email             = "dylanturn@gmail.com"
                    secret_base64_key = var.letsencrypt_secret_base64_key
                    default_issuer    = true,
                    ingress_class     = module.traefik.ingress_class
                  }
                }
              }              
            `}
        />
    );
}