import Divider from '../components/divider'
import { Container, Row, Col } from "react-bootstrap";

export function Roadmap() {

    const descRowStyle = {
        "margin-bottom": "2em",
        "max-height": '700px'
    }

    return (
        <>
            <Container>
                <Row>
                    <Col >
                        <h1 className="my-4" >Roadmap</h1>
                        <h2 className="mb-4" >Core Module Implementation Status</h2>
                        
                        <h3>Current Capability</h3>
                        <p>At this time 3 of the 5 core modules have been implemented. The implemented modules: terraform-kubernetes-argocd, terraform-kubernetes-cert-manager, and terraform-kubernetes-traefik are available via the Terraform public registry.</p>
                        <p>
                            More information on the implemented core modules can be found here:
                            - [terraform-kubernetes-argocd](/site-pages/octal-core/argocd.html)
                            - [terraform-kubernetes-cert-manager](/site-pages/octal-core/cert-manager.html)
                            - [terraform-kubernetes-traefik](/site-pages/octal-core/traefik.html)
                        </p>
                        <img className="mb-5 center shadow rounded-3" style={{ "width": "30em" }} src="/assets/images/project-octal-current-capability.png" />

                        <Divider />
                        
                        <h3>Planned Capability</h3>
                        <p>The work of implementing the remaining 2 core modules for Linkerd and Open Policy Agent is underway. Once the modules are complete they will be made available via the Terraform public registry.</p>
                        <p>
                            More information on the planned core modules can be found here:
                            - [terraform-kubernetes-linkerd](/site-pages/octal-core/linkerd.html)
                            - [terraform-kubernetes-opa](/site-pages/octal-core/open-policy-agent.html)
                        </p>
                        <img className="center shadow rounded-3" style={{ "width": "30em" }} src="/assets/images/project-octal-planned-capability.png" />
                        
                    </Col>
                </Row>
            </Container>
        </>
    );
}