
import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

export default class OctalComponentCard extends React.Component {

    constructor(props) {
        super(props);
        
        let windowDims = getWindowDimensions()
        this.state = {
            imageColOrder: "order-2",
            textColOrder: "order-3",
            windowWidth: windowDims.width
        };
      }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    renderList(renderableList){
        let renderedList = []
        renderableList.forEach(function(item, index, array) {
            renderedList.push(<li key={index}>{item}</li>)
        });
        return renderedList
    }

    renderFeatures(){
        if(this.props.features.length > 0){
            return(
                <>
                <span style={{"font-weight": "500"}}>Capabilities Provided</span>
                <ul >
                    {this.renderList(this.props.features)}
                </ul>
                </>
            )
        }
    }

    renderRelatedModules(){
        if(this.props.relatedModules.length > 0){
            return(
                <>
                <span style={{"font-weight": "500"}}>Related Modules</span>
                <ul >
                    {this.renderList(this.props.relatedModules)}
                </ul>
                </>
            )
        }
    }

    render() {

        const componentStyle={
            "box-shadow": "0 .15rem 0.50rem rgba(0,0,0,.20)",
        }

        const imgStyle = {
            "max-height": "12em"
        }

        if(this.props.imgRight && this.state.windowWidth > 769){
            this.state = {
                imageColOrder: "order-3",
                textColOrder: "order-2"
            };
        }
        return(
            <Card className="m-2 rounded-3 shadow-sm" style={{"width": "48%", "min-width": "500px"}}>
                <Row className="g-0 align-self-stretch">
                <Card.Header><h4 className="m-0">{this.props.title}</h4></Card.Header>
                </Row>
                <Row className="g-0 d-flex flex-grow-1 align-self-stretch">
                    <Col md="4" className="d-flex justify-content-center align-items-center">
                        <img src={this.props.imgSrc} style={{"max-height": "202px"}} className="img-fluid rounded-start" />
                    </Col>
                    <Col md="8" className="d-flex flex-grow-1 align-self-stretch">
                        <Card.Body>
                            <Card.Text>
                            <span style={{"font-weight": "500"}}>License: </span>{this.props.license}
                                <p>{this.props.description}</p>
                                {this.renderFeatures()}
                                {this.renderRelatedModules()}
                            </Card.Text>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        )
    }
}