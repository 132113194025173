import { Container, Row, Col } from "react-bootstrap";

export function SelectionCriteria() {
    return (
        <>
            <Container>
                <Row>
                    <Col >
                        <h3 id="project-octal-component-common-traits">Project Octal Tool Selection Criteria</h3>
                        <ul>
                            <li>Deployment and configuration can be done declaratively</li>
                            <li>Documentation is thorough and current</li>
                            <li>Available paid enterprise support</li>
                            <li>Associated with the CNCF in a meaningful way</li>
                            <li>Has seen general industry adoption</li>
                            <li>The tooling should be licensed under either Apache2.0 or MIT</li>
                        </ul>
                        <p>The most important requirement in the above list is the ability to deploy and manage the tool declaratively. The tool will be packaged as a Terraform module, and that module <strong>must</strong> be capable of managing the tools entire lifecycle. Other items such as “<em>Has seen general industry adoption</em>” are much harder to quantify and admittedly subjective. In these cases the decision will rely on experience and research.</p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}